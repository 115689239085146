
<template>
  <el-dialog :title="title" :close-on-click-modal="false"
             class="SNOWS-dialog SNOWS-dialog_center transfer-dialog" lock-scroll append-to-body
             v-bind="$attrs" width="800px" :modal-append-to-body="false" v-on="$listeners" @open="onOpen">
    <userTransfer v-model="selectedData" ref="userTransfer" multiple v-if="type==='user'" />
    <usersTransfer v-model="selectedData" ref="usersTransfer" multiple v-else-if="type==='users'" />
    <roleTransfer v-model="selectedData" ref="roleTransfer" multiple v-else-if="type==='role'" />
    <SNOWSTransfer :loading="loading" :treeData="treeData" v-model="selectedData" :type="type"
                   ref="SNOWSTransfer" multiple v-else />
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeTransfer">{{$t('cip.cmn.btn.celBtn')}}</el-button>
      <el-button type="primary" @click="confirm">{{$t('cip.cmn.btn.defBtn')}}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import SNOWSTransfer from '@/components/SNOWS-transfer'
import userTransfer from '@/components/SNOWS-userTransfer'
import usersTransfer from '@/components/SNOWS-usersTransfer'
import roleTransfer from './roleTransfer'
import {getDeptTree } from '@/api/system/dept'
import {getPostList} from '@/api/system/post'

export default {
  name: 'org-transfer',
  components: { SNOWSTransfer, userTransfer, usersTransfer, roleTransfer },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      default: 'user'
    },
    title: {
      type: String,
      default: '组织机构'
    },
  },
  data() {
    return {
      loading: false,
      treeData: [],
      selectedData: []
    }
  },
  methods: {
    onOpen() {
      this.dataInit()
    },
    closeTransfer() {

      this.$emit('update:visible', false)
    },
    confirm() {
      this.$emit('confirm', this.selectedData)
      this.closeTransfer()
    },
    dataInit() {
      this.loading = true
      this.selectedData = []
      this.treeData = []
      this.$nextTick(async () => {
        if (this.type === 'user') {
          this.selectedData = JSON.parse(JSON.stringify(this.value))
          this.$nextTick(() => {
            this.$refs.userTransfer && this.$refs.userTransfer.init()
          })
        } else if (this.type === 'users') {
          this.selectedData = JSON.parse(JSON.stringify(this.value))
          this.$nextTick(() => {
            this.$refs.usersTransfer && this.$refs.usersTransfer.init()
          })
        } else if (this.type === 'role') {
          this.selectedData = JSON.parse(JSON.stringify(this.value))
          this.$nextTick(() => {
            this.$refs.roleTransfer && this.$refs.roleTransfer.init()
          })
        } else {
          let res = []
          if (this.type === 'department') {
            let list = JSON.parse(localStorage.getItem('departmentList'))
            if(!list){
              await getDeptTree('000000').then(result=>{
                this.treeData = result.data.data
              })
              localStorage.setItem('departmentList',JSON.stringify(this.treeData))
            }else {
              this.treeData = list
            }
          }
          if (this.type === 'group') {
            res = await this.$store.dispatch('generator/getGroupTree')
          }
          if (this.type === 'position') {
            let list = JSON.parse(localStorage.getItem('positionList'))
            if(!list){
              await getPostList('000000').then(result=> {
                result.data.data.forEach(item => {
                  this.treeData.push({'id':item.id,'title':item.postName})
                })
              })
              localStorage.setItem('positionList',JSON.stringify(this.treeData))
            }else {
              this.treeData = list
            }
          }
          this.$refs.SNOWSTransfer && (this.$refs.SNOWSTransfer.filterText = '')
          // this.treeData = res
          this.selectedData = this.value
        }
        this.loading = false
      })
    }
  }
}
</script>
